module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Naturalmente Tecnologici","short_name":"NT","lang":"it","background_color":"#025300","theme_color":"#e98a15","display":"standalone","icon":"src/images/favicon/favicon.png","icons":[{"src":"src/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"a917b8cfbb0a40ab61c19e5ad684c905"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://nt.syskrack.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","it"],"defaultLanguage":"it","siteUrl":"https://nt.syskrack.org","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KLQBPFW","includeInDevelopment":false,"routeChangeEventName":"ROUTE_CHANGE_EVENT","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
