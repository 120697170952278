exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contattaci-tsx": () => import("./../../../src/pages/contattaci.tsx" /* webpackChunkName: "component---src-pages-contattaci-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-programma-tsx": () => import("./../../../src/pages/programma.tsx" /* webpackChunkName: "component---src-pages-programma-tsx" */),
  "component---src-templates-edizioni-index-tsx": () => import("./../../../src/templates/Edizioni/index.tsx" /* webpackChunkName: "component---src-templates-edizioni-index-tsx" */)
}

